<template lang="">
<div>
    <v-container class="pa-2 d-flex justify-center">
        <v-col lg="4" sm="6" style="padding: 0px;">
            <br>

            <v-container class="">
                <v-row class="d-flex align-center">
                    <v-col cols="2">

                    </v-col>
                    <v-col cols="8" class="d-flex justify-center align-center">
                    </v-col>
                    <v-col cols="2">
                        <v-btn elevation="0" style="margin-left: -17px;" fab color="#F3EFF7" @click="stopTesting">
                            <v-icon x-large></v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-img style="transform: translate(25px,-80px); z-index:1;" position="center center" src="@/assets/img/checkmong/simple_azq/IconResult.png" contain max-height="800px"></v-img>
                <v-card class="xcard">
                    <v-card-title class="pt-2">
                        <h4>คะแนนของท่านอยู่ระหว่าง</h4>
                        <v-chip class="ml-1" color="#F5DEED"><span style="color:#AE1B77;">
                                <h4>{{point_text}}</h4>
                            </span></v-chip>
                    </v-card-title>
                    <!-- <v-card shaped style="color:#AE1B77;" elevation="1" class="ml-3 mr-3">
                        <h2 class="pa-4">{{result_text}}</h2>
                    </v-card> -->
                    <h2 class="pl-4 pt-0 pb-0 pr-4" style="color:#AE1B77;text-align:center;">{{result_text}}</h2>
                    <hr class="ml-4 mr-4" style="color:#CE72AB;border: 1px solid;">
                    <v-card v-if="this.point_num <= 19" height="220" class="overflow-auto">
                        <v-card-text style="color:#460B30;">
                            <span style="font-size: 17px;">
                                แนะนำให้มาคัดกรองอย่างสม่ำเสมอทุก 3-6 เดือนต่อครั้ง
                            </span>
                        </v-card-text>
                    </v-card>
                    <v-card v-else height="220" class="overflow-auto">
                        <v-card-text style="color:#460B30;">
                            <h3>วิธีป้องกันสมองเสื่อม</h3>
                            <span style="font-size: 17px;">
                                1.ออกกำลังกายเป็นประจำ<br>
                                2.ออกกำลังกายสมองอยู่เสมอ<br>
                                3.เข้าสังคมบ้าง<br>
                                4.รับประทานอาหารที่มีประโยชน์<br>
                                5.พักผ่อนให้เพียงพอและคลายเครียด<br>
                                6.ตรวจร่างกายเป็นประจำ<br>
                                <br>
                            </span>
                            <h3>วิธีฝึกสมองอย่างง่ายๆ</h3>
                            <span style="font-size: 17px;">
                                • ความจำ (ความจำระยะสั้น/ยาว ความจำเกี่ยวกับข้อเท็จจริง)<br>
                                &nbsp;&nbsp;&nbsp;o เกมจำตัวเลข เกมทศกัณฑ์<br>
                                • ความสามารถด้านภาษา (การเรียกชื่อ การหาคำ ความเข้าใจภาษา)<br>
                                &nbsp;&nbsp;&nbsp;o เกมอักษรไขว้ เกมทายคำ<br>
                                • สมาธิ (การมีสมาธิอย่างต่อเนื่อง การจดจ่อต่อสิ่งใดสิ่งหนึ่งโดยไม่สนใจสิ่งรอบข้าง)<br>
                                &nbsp;&nbsp;&nbsp;o เกมจัดผิดภาพ เกมจับคู่<br>
                                • มิติสัมพันธ์ (การมอง การทำงานประสานกัน)<br>
                                &nbsp;&nbsp;&nbsp;o เกมทายภาพ<br>
                                • การบริหารจัดการ (การวางแผน การตัดสินใจ การแก้ปัญหา)<br>
                                &nbsp;&nbsp;&nbsp;o หมากรุก หมากฮอส<br>
                                • ปฏิสัมพันธ์ทางสังคม (การเข้าใจตนเองและคนรอบข้าง)<br>
                                &nbsp;&nbsp;&nbsp;o พบปะพูดคุยกับคนรอบข้าง<br>
                            </span>
                        </v-card-text>
                    </v-card>
                    <v-card-actions class="">
                        <v-container>
                            <v-btn v-if="this.point_num <= 19" elevation="0" x-large block rounded class="btn-pink mb-4" @click="goCogmate">
                                <h2 style="font-weight: bold; letter-spacing: -1px; ">รับข้อมูลเพิ่มเติม</h2>
                            </v-btn>
                            <v-btn v-else elevation="0" x-large block rounded class="btn-pink mb-4" @click="goSearchDoctor">
                                <h2 style="font-weight: bold; letter-spacing: -1px; ">ปรึกษาหมอใกล้บ้าน</h2>
                            </v-btn>

                            <v-btn elevation="0" x-large block rounded outlined color="#AE1B77" class="" @click="goKnowladge">
                                <h2 style="font-weight: bold; letter-spacing: -1px; ">ความรู้ภาวะสมองเสื่อม</h2>
                            </v-btn>
                        </v-container>

                    </v-card-actions>

                </v-card>

                <br>
                <br>
                <br>

            </v-container>
        </v-col>
    </v-container>
</div>
</template>

<script>
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
export default {
    data() {
        return {
            logo: require('@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png'),
            nameRules: [
                v => !!v || "โปรดกรอกข้อมูล",

            ],
            valid: true,
            is_btn_active: false,
            ans: null,
            point_text: null,
            result_text: null,
            point_num: null
        };
    },
    watch: {
        ans(val) {
            if (val) {
                console.log(val)
                this.checkInput()
            } else {
                console.log(val)
            }
        },
    },
    async beforeCreate() {
        window.liff.ready
        // await window.liff.init({
        //     liffId: LIFFID
        // }).then(() => {
        //     //console.log('done window.liff.init')
        // });
    },
    methods: {
        goKnowladge() {
            this.$router.push('/select_brain_library')
        },
        goSearchDoctor() {
            this.$router.push('/find_provider')
        },
        goCogmate() {
            window.open("https://cogmatethailand.com/get-started#special-package")
        },

        stopTesting() {
            window.liff.closeWindow();

        },
        async submitVal() {
            const simple_azq_ans = this.$store.state.simple_azq_ans
            //console.log(azq_ans_temp)
            // let payload = {
            //     "ans_1": simple_azq_ans.ans_1,
            //     "ans_2": simple_azq_ans.ans_2,
            //     "ans_3": simple_azq_ans.ans_3,
            //     "ans_4": simple_azq_ans.ans_4,
            //     "ans_5": simple_azq_ans.ans_5,
            //     "ans_6": simple_azq_ans.ans_6,
            //     "ans_7": simple_azq_ans.ans_7,
            //     "ans_8": simple_azq_ans.ans_8,
            //     "ans_9": simple_azq_ans.ans_9,
            //     "ans_10": simple_azq_ans.ans_10,
            //     "ans_11": simple_azq_ans.ans_11,
            //     "ans_12": simple_azq_ans.ans_12,
            //     "ans_13": simple_azq_ans.ans_13,
            //     "ans_14": simple_azq_ans.ans_14,
            //     "score": this.point_num,
            //     "age": parseInt(simple_azq_ans.age,10),
            //     "full_name": simple_azq_ans.full_name,
            //     "phone_number": simple_azq_ans.phonenumber,
            // }
            // console.log('payload: ',payload)
            await Vue.axios.post(APIURL + '/submit_simple_azq_records', {
                "ans_1": simple_azq_ans.ans_1,
                "ans_2": simple_azq_ans.ans_2,
                "ans_3": simple_azq_ans.ans_3,
                "ans_4": simple_azq_ans.ans_4,
                "ans_5": simple_azq_ans.ans_5,
                "ans_6": simple_azq_ans.ans_6,
                "ans_7": simple_azq_ans.ans_7,
                "ans_8": simple_azq_ans.ans_8,
                "ans_9": simple_azq_ans.ans_9,
                "ans_10": simple_azq_ans.ans_10,
                "ans_11": simple_azq_ans.ans_11,
                "ans_12": simple_azq_ans.ans_12,
                "ans_13": simple_azq_ans.ans_13,
                "ans_14": simple_azq_ans.ans_14,
                "score": this.point_num,
                "age": parseInt(simple_azq_ans.age, 10),
                "full_name": simple_azq_ans.full_name,
                "phone_number": simple_azq_ans.phonenumber,

            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(() => {})

        },
        cal_point() {
            const simple_azq_ans = this.$store.state.simple_azq_ans
            console.log(simple_azq_ans)
            let point = (
                parseInt(simple_azq_ans.ans_1, 10) +
                parseInt(simple_azq_ans.ans_2, 10) +
                parseInt(simple_azq_ans.ans_3, 10) +
                parseInt(simple_azq_ans.ans_4, 10) +
                parseInt(simple_azq_ans.ans_5, 10) +
                parseInt(simple_azq_ans.ans_6, 10) +
                parseInt(simple_azq_ans.ans_7, 10) +
                parseInt(simple_azq_ans.ans_8, 10) +
                parseInt(simple_azq_ans.ans_9, 10) +
                parseInt(simple_azq_ans.ans_10, 10) +
                parseInt(simple_azq_ans.ans_11, 10) +
                parseInt(simple_azq_ans.ans_12, 10) +
                parseInt(simple_azq_ans.ans_13, 10) +
                parseInt(simple_azq_ans.ans_14, 10)
            )
            this.point_num = point
            if (point < 19) {
                this.point_text = '14-19 คะแนน'
                this.result_text = 'สมรรถภาพทางสมองอยู่ในเกณฑ์ดีมาก'
            } else if (point >= 20 && point <= 29) {
                this.point_text = '20-29 คะแนน'
                // this.result_text = 'สมรรถภาพทางสมองอยู่ในระดับปานกลาง ควรฝึกปรับปรุงความจำให้ดีขึ้น'
                this.result_text = 'สมรรถภาพทางสมองอยู่ในระดับปานกลาง ควรเข้ารับคำแนะนำเพื่อเพิ่มสมรรถภาพทางสมอง'
            } else if (point >= 30 && point <= 39) {
                this.point_text = '30-39 คะแนน'
                this.result_text = 'สมรรถภาพทางสมองอยู่ในระดับค่อนข้างต่ำ ควรเข้ารับคำแนะนำเพื่อเพิ่มสมรรถภาพทางสมอง'
            } else if (point >= 40) {
                this.point_text = '40-56 คะแนน'
                this.result_text = 'สมรรถภาพทางสมองต่ำ ควรพบแพทย์'
            }
        }
    },

    mounted() {

        this.cal_point()
        this.submitVal()

    },
    created() {},
    beforeUpdate() {

    },
    updated() {
        //console.log('updated')

    }

};
</script>

<style scoped>
.footer-sm {
    position: absolute;
    bottom: 0;
    left: 0;
}

.btn-pink {
    background: #AE1B77 !important;
    color: white !important;
    box-shadow: 4px 1px 4px rgba(201, 200, 200, 0.25) !important;
    font-weight: bold !important;
}

.text-pink {
    color: #601550 !important;
}

.text-size {
    font-size: 18px;
}

.xcard {
    background: #FFFFFF;
    box-shadow: 4px 7px 10px rgba(255, 184, 184, 0.2);
    border-radius: 10px;
    color: #810E45;
    margin-top: -300px;
    z-index: 2;
}

.transp-btn {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 9px;
    height: 70px;
    padding: 18px;
}

.radio-active {
    background: #BE4691;
    color: white !important;
}
</style>
